import React from "react"
import Layout from "../components/layout"
import Logo from "../components/images/logo"

const NotFoundPage = () => (
  <Layout style={{ backgroundColor: `black`, height: `100vh`, textAlign: `center`, padding: `10vw` }}
    lang="en" title="Rodrigo de Alvarenga" link="https://www.rodrigodealvarenga.com/404" description="Rodrigo de Alvarenga is a venture builder, board member, advisor, mentor, speaker & professor expert in business transformation, innovation, startups & impact.">
    <Logo style={{ width: `10vh`, margin: `auto` }} />
    <h1 style={{ color: `#ddd`, marginTop: `1em` }}>NOT FOUND</h1>
    <p>Maybe the page that you were looking for is no longer available. To go back to the main page click in the link below:</p>
    <a href="/">Go back</a>
  </Layout>
)

export default NotFoundPage
